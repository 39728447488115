//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import DatePicker from "vue2-datepicker";


import { maxLength, minLength, required} from "vuelidate/lib/validators";
import axios from "axios";
import Popup from '../../../components/Popup.vue';
//import CreateBanner from '../../../components/management/banners/CreateBanner.vue';

import Formatter from "@/components/libraries/Formatter";
import Paginator from '../../../components/Paginator.vue';
//import EditBanner from '../../../components/management/banners/EditBanner.vue';
const formatter = new Formatter();
//import Paginator from "@/components/Paginator";
//import Popup from "@/components/Popup";

/**
 * Rbac component
 */
export default {
  components: {
    Popup,
   // Paginator,
    Layout,
    PageHeader,
    DatePicker,
    Paginator
    //CreateBanner,
    //EditBanner
  },
  data() {
    return {
      thisPage: 1, // Текущая страница
      pagesCountPaginator: 0, // Кол-во страниц
      pageSize: 25, // Сколько записей подгружать на 1 страницу
      submitted: false,

      topics: [],

      notifications: [],
      newNotification: {
        send_to: '',
        title: '',
        message: '',
        create_dt: ''
      },

      addPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.confirm'),
        actionClass: "btn-success"
      },

      deletePopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.confirm'),
        actionClass: "btn-success"
      },
      deletedNotificationId: 0,

      pickerLang: {
        formatLocale: {
          firstDayOfWeek: 1,
          months: this.$t('datepicker.months'),
          weekdaysMin: this.$t('datepicker.weekdaysMin'),
          monthsShort: this.$t('datepicker.monthsShort'),
        },
        monthBeforeYear: false,
      },

      title: this.$t('menuitems.management.list.push_notifications'),
      items: [
        {
          text: this.$t('menuitems.management.text'),
        },
        {
          text: this.$t('menuitems.management.list.push_notifications'),
          active: true
        }
      ],
      //data: [],
    };
  },
  // validations: {
  //   sumPoint: { required, minValue: minValue(1), maxValue: maxValue(5000) }
  // },
  computed: {

  },
  watch: {
    
  },
  validations: {
    newNotification: {
      send_to: { required },
      title: { required, minLength: minLength(1), maxLength: maxLength(120) },
      message: { required, minLength: minLength(1), maxLength: maxLength(350) },
      create_dt: { required }
    }
  },
  methods: {
    showDeletePopup(notif_id, status){
      if(+status !== 0){
        this.$store.dispatch("addNotification", {
          text: this.$t('views.push_notifications.stop_delete'),
          time: 6,
          color: "danger"
        });
        return false;
      }
      
      this.deletedNotificationId = notif_id;
      this.deletePopup.show = true;
    },
    closeDeletePopup(){
      this.deletePopup.show = false;
    },
    deletePopupSubmit(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append('id', this.deletedNotificationId);

      axios
          .post(`/v1/push/delete`, formData)
          .then(() => {
            this.$store.dispatch("stopPreloader");
            this.closeDeletePopup();
            this.loadList();
          })
          .catch(err => {
            this.$store.dispatch("stopPreloader");
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },

    showAddPopup(){
      this.addPopup.show = true;
    },
    closeAddPopup(){
      this.addPopup.show = false;
    },
    addPopupSubmit(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append('send_to', this.newNotification.send_to);
      formData.append('title', this.newNotification.title);
      formData.append('message', this.newNotification.message);
      formData.append('create_dt', formatter.jsObjectToPhpDatetime(this.newNotification.create_dt));

      axios
          .post(`/v1/push/create`, formData)
          .then(() => {
            this.$store.dispatch("stopPreloader");
            this.closeAddPopup();
            this.newNotification.send_to = '';
            this.newNotification.title = '';
            this.newNotification.message = '';
            this.newNotification.create_dt = '';
            this.loadList();
          })
          .catch(err => {
            this.$store.dispatch("stopPreloader");
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    setPage(val) {
      this.thisPage = val;
      this.loadList();
    },
    loadList(){
      this.$store.dispatch("startPreloader");

      let formData = new FormData();
      formData.append("page", this.thisPage);
      formData.append("page_size", this.pageSize);

      axios
        .post(`/v1/push/get-list`, formData)
        .then(resp => {
          this.pagesCountPaginator = resp.data.pages;
          this.notifications = resp.data.data;
          this.$store.dispatch("stopPreloader");
        })
        .catch(err => {
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch("stopPreloader");
        });
    },
    getTopics(){
      axios
          .post(`/v1/push/get-topics`)
          .then(resp => {
            this.topics = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    }
  },
  created() {
    this.getTopics();
    this.loadList();
  }
};
