//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Paginator",
  components: {},
  data() {
    return {
      data: []
    };
  },
  props: {
    pages: Number, // Кол-во страниц
    now: Number // Текущая страница
  },
  watch: {
    pages: function() {
      this.setPaginator();
    },
    now: function() {
      this.setPaginator();
    }
  },
  computed: {},
  methods: {
    setPaginator() {
      this.data = [];
      for (let i = 1; i <= +this.pages; i++) {
        if (
          i <= 2 ||
          (i >= +this.now - 1 && i <= +this.now + 1) ||
          i >= +this.pages - 1
        ) {
          if (i !== 1 && +this.data[this.data.length - 1] + 1 !== i) {
            this.data[this.data.length] = "..";
          }
          this.data[this.data.length] = i;
        } else {
          continue;
        }
      }
    },
    setPage(val) {
      this.$emit("setPage", val);
    }
  },
  created() {
    this.setPaginator();
  }
};
